<template>
    <Layout title="订单列表">  
        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-search"></i>
                <span class="title">筛选条件</span>
            </template>
            <el-form :model="queryForm" inline ref="queryFormRef" size="small">
                <el-form-item label="用户名" prop="applicationName">
                    <el-input v-model="queryForm.applicationName" clearable />
                </el-form-item>
                <el-form-item label="手机号码" prop="applicationTel">
                    <el-input v-model="queryForm.applicationTel" clearable />
                </el-form-item>
                <el-form-item label="订单编号" prop="orderSn">
                    <el-input v-model="queryForm.orderSn" clearable />
                </el-form-item>
                <el-form-item label="订单状态" prop="delStatus" clearable>
                    <el-select v-model="queryForm.delStatus" placeholder="请选择付款状态">
                        <el-option label="全部" :value="0" />
                        <el-option label="正常" :value="1" />
                        <el-option label="已取消" :value="2" />
                    </el-select>
                </el-form-item>
                <el-form-item label="付款状态" prop="payStatus" clearable>
                    <el-select v-model="queryForm.payStatus" placeholder="请选择付款状态">
                        <el-option label="全部" :value="0" />
                        <el-option label="已付款" :value="1" />
                        <el-option label="未付款" :value="2" />
                    </el-select>
                </el-form-item>
                <el-form-item label="订单类型" prop="typeId" clearable>
                    <el-select v-model="queryForm.typeId" placeholder="请选择订单类型">
                        <el-option label="全部" :value="0" />
                        <el-option label="课件订单" :value="1" />
                        <el-option label="积分订单" :value="2" />
                    </el-select>
                </el-form-item>
                <el-form-item label="课程名称" prop="keywords">
                    <el-input v-model="queryForm.keywords" clearable />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="handleQuery">确定</el-button>
                    <el-button type="default" icon="el-icon-refresh-left" @click="handleReset('queryFormRef')">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-s-grid"></i>
                <span class="title">数据列表</span>
                <el-button-group>
                    <el-button type="success" icon="el-icon-download" @click="handleExport">导出数据</el-button>
                </el-button-group>
            </template>

            <el-table :data="tableData" v-loading="loading">       
                <el-table-column type="index" width="50"></el-table-column>        
                <el-table-column prop="orderSn" label="订单编号" align="center" width="130"></el-table-column>  
                <el-table-column label="订单类型" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.typeId === 0 ? '课件订单' : '积分订单' }}
                    </template>
                </el-table-column> 
                <el-table-column label="订单状态" align="center">
                    <template slot-scope="scope">                        
                        <el-tag :type="scope.row.isDel === 1 ? 'danger' : 'success'">{{ ['正常', '删除'][scope.row.isDel] }}</el-tag>
                    </template>
                </el-table-column> 
                <el-table-column prop="courseName" label="课程名称" align="center" min-width="180" show-overflow-tooltip></el-table-column>
                <el-table-column prop="InvoiceStatus" label="发票状态" align="center"></el-table-column>  
                <el-table-column prop="applicantsName" label="姓名" align="center"></el-table-column>
                <el-table-column prop="applicantsIdCard" label="身份证号码" align="center" width="180"></el-table-column>
                <el-table-column prop="applicantsTel" label="电话号码" align="center" min-width="120"></el-table-column>
                <el-table-column label="付款状态" align="center">
                    <template slot-scope="scope">
                        {{ getPayStatus(scope.row.payStatus) }}
                    </template>
                </el-table-column>   
                <el-table-column prop="InvoiceStatus" label="发票状态" align="center"></el-table-column> 
                <el-table-column prop="totalAmount" label="订单金额" align="center"></el-table-column> 
                <el-table-column prop="addTimeString" label="创建时间" width="180" align="center"></el-table-column>      
                <el-table-column fixed="right" label="操作" width="180" align="center">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button type="primary" plain icon="el-icon-view" size="mini" @click.native="handleView(scope.row)">查看</el-button>
                            <el-button type="primary" icon="el-icon-view" size="mini" @click.native="handleEdit(scope.row)">编辑</el-button>
                        </el-button-group>                                             
                    </template>
                </el-table-column>
            </el-table>

            <div class="el-card__footer">
                <div></div>
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :page-count="totalPage"
                    @current-change="handleCurrentPageChange"
                ></el-pagination> 
            </div>
        </el-card>

        <ModifyDialog v-if="isLoadModifyDialog" />
    </Layout>
</template>

<script>
import ModifyDialog from './components/ModifyDialog.vue';
import { getOrderListApi } from '@/api/order';

export default {
    components: {
        ModifyDialog
    },

    data() {
        return {
            queryForm: {
                pageSize: 5,
                currentPage: 1,
                typeId: '',
                payStatus: '',
                keywords: '',
                orderSn: '',
                applicationName: '',
                applicationTel: '',
                delStatus: ''
            },

            loading: false,

            tableData: [],
            totalPage: 1,

            isLoadModify: false,

            isLoadModifyDialog: false
        };
    },

    methods: {
        // 获取数据
        getData() {
            getOrderListApi(this.queryForm).then(res => {
                if (res.data) {
                    const { allNum, listData } = res.data;

                    this.tableData = listData;
                    this.totalPage = Math.ceil(allNum / this.queryForm.pageSize);
                }
            });
        },

        handleCurrentPageChange(e) {
            this.queryForm.currentPage = e;
            this.getData();
        },

        // 导出数据
        handleExport() {
            const payload = {
                ...this.queryForm,
                isExport: 1
            };
            getOrderListApi(payload).then(res => {
                if (res.data && res.data.url) {
                    const url = res.data.url;
                    const link = document.createElement('a');
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();  
                } else {
                    this.$alert('导出数据为空', '提示');
                }              
            });
        },

        // 付款状态
        getPayStatus(status) {
            return ['待付款', '部分付款', '完全付款'][Number(status)];
        },

        // 搜索
        handleQuery() {
            this.getData();
        },

        // 重置
        handleReset(formName) {
            console.log(formName);
            this.$refs[formName].resetFields();
        },

        // 查看详情
        handleView(rowData) {
            const { orderSn } = rowData;
            this.$router.push({
                path: '/order/detail',
                target: '_blank',
                query: {
                    orderSn
                }
            });
        },

        // 编辑
        handleEdit(rowData) {
            this.isLoadModifyDialog = true;
            setTimeout(() => {
                this.$eventBus.$emit('OPEN_ORDER_MODIFY_DIALOG', rowData);
            });
        }
    },

    mounted() {
        this.getData();
    }
};
</script>

<style lang="scss" scoped>
.custom-card__outer {
    .custom-toolbar {
        display: flex;
        align-items: center;
        ::v-deep .el-button {
            margin-right: 20px;
            margin-left: 0;
        }
        .info {
            font-size: 30px;
            color: #f56c6c;
        }
    }
}
</style>
